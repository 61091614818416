@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/@fortawesome/fontawesome/styles.css";

//Global Styling
$theme-colors: (
    "blue":      #238dc1,
    "green":     #45a041,
    "dk-gray":   #76777a,
    "lt-gray":   #e0e1e6
);

body { background-color: #dfe0e5;color: #76777a; }

h1, h2, h3, h4, h5, h6, .btn { font-family: 'Lato', sans-serif; color: theme-color('dk-teal');}
body { font-family: 'Lato', sans-serif; }

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

a { color: theme-color('blue') }
a:hover { color: theme-color('green') }

img { width: 100%; }

.btn { 
    display: inline-block;
    padding: 7px 40px; 
    margin: 5px 0px;
    color: #fff;
    border-radius: 0px;
    background-color: theme-color('green'); 
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.btn:hover { 
    color: #fff;
    background-color: theme-color('blue'); 
}

//Footer
footer {
    border-top: solid 5px theme-color('green');
    background-color: #fff;
}
footer a {
    color: #76777a;
}
footer a:hover {
    text-decoration: none;
    color: theme-color('blue');
}
#footer-menu {
    padding-top: 50px;
    padding-bottom: 50px;
}
#footer-menu hr {
    border-top: 1px solid rgba(118,119,122, 1);
}
#footer-copyright {
    background-color: theme-color('lt-gray');
    padding: 15px 0px;
    font-size: 12px;
    color: #76777a;
}
#footer-copyright img {
    width: 100%;
    height: 40px;
}

// Header
#home-header {
    
}
#sub-header {
    padding: 5px;
    top: 0;
    position: relative;
    z-index: 5;
}
#sub-header a {
    color: theme-color('green');
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}
#sub-header a:hover {
    color: theme-color('blue');
    text-decoration: none !important;
}
#sub-header a .top-header-icon {
    fill: theme-color('green');
    -webkit-transition: fill .2s linear;
    transition: fill .2s linear;
}
#sub-header a:hover .top-header-icon {
    fill: theme-color('blue');
}
#sub-header svg {
    width: 30px;
}
#sub-header span {
    display: inline-block;
    margin: 0px 0px 7px 5px;
}
#sub-header .head-rt-icon {
    padding-right: 5px;
}
#sub-header .head-lt-icon {
    padding-left: 5px;
}
#sub-header .cls-1{
    stroke:#76777a;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:3px
}

//Nav Bar
header {
    position: sticky;
    top: 0px;
    z-index: 5;
}
#home-header {
    position: relative;
}
#home-header #nav-header{
    position: sticky;
    top: 0;
    z-index: 5;
}
#home-nav-container {
    position: sticky;
    top: 0px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    border-top: solid 1.5px #fff;
    border-bottom: solid 1.5px #fff;
    -webkit-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}
#home-nav-container.scrolled {
    max-width: 100% !important;
    border-top: solid 1.5px #76777a !important;
    border-bottom: solid 1.5px #76777a !important;
    background-color: #fff;
}
#nav-container {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: solid 1.5px #76777a;
    border-bottom: solid 1.5px #76777a;
    background-color: #fff;
}
#site-logo a {
    display: flex;
}
#site-logo svg {
    width: 250px;
    height: 85.72px;
}
#site-logo .st0 {
    fill: #238dc1;
}
#site-logo .st1{
    fill: #76777a;
}
#site-logo .st2 {
    fill: #45a041;
}

#home-nav-container #site-logo .st0 {
    fill: #fff;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
#home-nav-container #site-logo .st1{
    fill: #fff;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
#home-nav-container #site-logo .st2 {
    fill: #fff;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.logo-scrolled-st0 {
    fill: #238dc1 !important;
}
.logo-scrolled-st1 {
    fill: #76777a !important;
}
.logo-scrolled-st2 {
    fill: #45a041 !important;
}
#nav-header nav {
    align-self: center;
}
#main-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
#main-menu > li {
    position: relative;
    white-space: nowrap;
    display: inline-block;
    font-size: 1.4em;
    padding-left: 15px;
} 
#main-menu > li:before {
    content: url('../img/Hover-Dots-Icon.svg');
    position: absolute;
    width: 30px;
    top: 23px;
    height: 38px;
    opacity: 0;
    display: block;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
} 
#nav-about:before {
    left: 30px;
}
#nav-products:before {
    left: 42px;
}
#nav-services:before {
    left: 38px;
}
#nav-send-case:before {
    left: 55px;
}
#nav-resources:before {
    left: 50px;
}
#nav-contact:before {
    left: 53px;
}
#main-menu > li:hover:before {    
    opacity: 1;
} 
#main-menu > li:hover .sub-nav {
    opacity: 1;
    visibility: visible;
}
#main-menu > li > span {
    color: #76777a;
    cursor: default;
}
#home-nav-container #main-menu span {
    color: #fff;
    cursor: default;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.home-nav-scrolled {
    color: #76777a !important;  
}
#main-menu li a {
    color: #76777a;
    -webkit-transition: color .2 linear;
    transition: color .2s linear;
}
#home-nav-container #main-menu li a {
    color: #fff;
    -webkit-transition: color .2 linear;
    transition: color .2s linear;
}
#main-menu > li > a:hover {
    color: theme-color('blue');
    text-decoration: none;
}
#home-nav-container #main-menu > li > a:hover {
    color: theme-color('blue');
    text-decoration: none;
}
.sub-nav {
    list-style: none;
    position: absolute;
    font-size: .8em;
    z-index: 2;
    visibility: hidden;
    min-width: 100%;
    opacity: 0;
    padding-left: 0px;
    padding: 5px 0px;
    transition: all .2s linear;
    background-color: rgba(255, 255, 255, .95);
    left: 0;
    top: 62px;
}
.sub-nav > li {
    white-space: nowrap;
    background-color: transparent;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.sub-nav > li:hover {
    background-color: #fff;
}
.sub-nav > li > a {
    display: block;
    padding: 3px 10px;
    color: #76777a !important;
    text-decoration: none;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}
.sub-nav > li:hover > a {
    color: theme-color("blue") !important;
}
/* Moblie Menu */
#mobile-menu-icon {
    padding-left: 15px;
}
#mobile-menu-icon svg {
    width: 100%;
    max-width: 65px;
    min-width: 50px;
    fill: #76777a;
}
#mobile-menu-icon svg:hover {
    cursor: pointer;
}
#home-nav-container #mobile-menu-icon svg {
    fill: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
#home-nav-container #mobile-menu-icon svg.home-nav-scrolled {
    fill: #76777a;
}
#mobile-nav-btn {
    align-self: center;
}
#mobile-menu {
    position: fixed;
    width: 200px;
    height: 100vh;
    background-color: #76777a;
    z-index: 3;
    left: -303px;
    top: 0;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;  
}
#mobile-menu.open{
    left: 0px;
}
.mobile-menu-txt {
    text-align: right;
    padding-right: 15px;
    opacity: 0;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 500px;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
.mobile-menu-txt ul > li {
    list-style: none;
}
.mobile-menu-txt ul > li > a {
    color: #fff;
}
.mobile-menu-txt.open {
    opacity: 1;
    bottom: auto;
    top: 70px;
}
#close-mobile {
    text-align: right;
    font-size: 28px;
    color: #fff;
    padding-right: 30px;
    padding-top: 25px;
}
#close-mobile:hover {
    cursor: pointer;
}
#menu-mobile-menu li {
    position: relative;
    padding: 5px 15px;
    border-right: solid 0px;
    color: #fff;
}
// Home Page Slider
#home-slider {
    position: relative;
    height: 544px;
    margin-top: -144px;
    background-image: url(/img/7799-Send-Your-Case-Banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.home-slide-bckd {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
}
#home-slide-2-image {
    background-image: url(/img/7799-Full-Service-Banner.jpg);
}
#home-slide-3-image {
    background-image: url(/img/7799-Full-Contour-Zirconia-Banner.jpg);
}
#home-slide-2-image.hide-img, #home-slide-3-image.hide-img {
    opacity: 0;
}
#slider-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
}
#slider-grid div {
    grid-column-start: 1;
    grid-row-start: 1;
    text-align: center;
    align-self: center;
    padding-top: 144px;
}
#home-slider .container, #home-slider .row {
    height: 100%;
}
.home-slide h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 2.0em;
    margin-bottom: .5em;
}
.home-slide h2 {
    color: #fff;
    font-size: 1.3em;
    margin-bottom: 1em;
}
.home-slide .btn {
    margin-top: .5em;
}
.home-slide {
    opacity: 0;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.home-slide.active-slide {
    opacity: 1;
}
#home-slide-backArrow {
    position: absolute;
    top: 0;
    bottom: 0;
}
#home-slide-backArrow img {
    max-width: 40px;
    margin-top: 350px;
    margin-left: 15px;
}
#home-slide-nextArrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
}
#home-slide-nextArrow img {
    max-width: 40px;
    margin-top: 350px;
    margin-left: 15px;
}
#home-slide-backArrow:hover, #home-slide-nextArrow:hover {
    cursor: pointer;
}
// Home Page Content
#home-cta {
    padding: 20px 0px;
    background-color: theme-color('blue'); 
}
#home-cta {
    color: #fff;
}
#home-cta .btn {
    align-self: center;  
}
#home-cta .btn:hover {
    background-color: #fff;
    color: theme-color('blue');
}

//Home Body
#home-body {
    padding: 50px 0px 25px 0px;
}
.icon-boxes-grid {
    display: grid; 
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2em;
}
.icon-box {
    display: grid; 
    grid-template-columns: repeat(2, 1fr);
    box-shadow: 0px 0px 10px .5px #a8a6a6;
}

.icon-box:hover .icon-box-img{
    background-color: theme-color('blue');
}
.icon-box .icon-box-img {
    background-color: theme-color('green');
    text-align: center;
    display: flex;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.icon-box svg {
    margin: auto;
    width: 30%;
    min-width: 87px;
    align-self: center;
}
.icon-box .svg-icon {
    fill: #fff;
}
.icon-box-txt {
    background-color: #fff;
}
.icon-box-txt .icon-box-txt-wrap {
    margin: auto;
    width: 90%;
    padding: 20px 0px;
}
.icon-box-txt h2, .icon-box-txt p {
    color: #76777a;
}
.icon-boxes-grid > a:hover {
    color: #76777a;
    text-decoration: none;
}
.icon-box-txt h2 {
    color: #76777a;
}
.icon-box-txt hr {
    border-top: 1px solid rgba(118,119,122, 1);
}

#seo-section {
    padding: 25px 0px 25px 0px;
}
#seo-wrapper {
    box-shadow: 0px 0px 10px .5px #a8a6a6;
}
#seo-text-wrap hr {
    border-top: 1px solid rgba(118,119,122, 1);
}
#seo-txt {
    padding: 25px 20px; 
    background-color: #fff;
}
#seo-img {
    background-image: url('/img/7799-Resource-for-Remarkable-Smiles-HomePage-Thumb.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#home-testimonials {
    padding: 25px 0px;
    margin-top: 25px;
    background-image: url('/img/7799-Dot-Connect-Pattern.png'); 
}
#home-testimonials p {
    color: #fff;
    font-size: 1.1rem;
}
#home-testimonials .svg-icon {
    fill: #fff;
}
#testimonial-wrapper {
    text-align: center;
}
#testimonial-wrapper svg {
    width: 75px;
}

/* Internal Pages */
#defualt-body {
    margin: 50px 0px;
}
#page-title {
    display: flex;
    padding: 15px 25px;
    background-color: theme-color('green');
}
#page-title h1 {
    color: #fff;
    align-self: center;
    margin-left: 25px;
    margin-bottom: 0px;
}
#page-title svg {
    width: 60px;
    height: 60px;
}
#page-title .svg-icon {
    fill: #fff;
}
#body-wrapper {
    box-shadow: 0px 0px 10px .5px #a8a6a6;
    background-color: #fff;
}
#body-container {
    width: 100%;
    padding: 50px 25px;
    margin-right: auto;
    margin-left: auto;
}
#body-container hr {
    border-top: 1px solid rgba(118,119,122, 1);
    margin-top: .5em;
    margin-bottom: .5em;
}

/* Mission Statement */
#mission_Statement {
    padding: 25px 0px;
    margin-top: 25px;
    background-image: url('/img/7799-Dot-Connect-Pattern.png');
    text-align: center;
}
#mission_Statement h2, #mission_Statement h3 {
    color: #fff;
}

/* Product Listing */
#product-listing {
    display: grid; 
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2em;
    padding: 25px 0px;
}
.product-thumb {
    background-color: #fff;
    box-shadow: 0px 0px 10px .5px #a8a6a6;
    position: relative;
}
.product-thumb img {
    padding-bottom: 60px;
}
.product-title {
    padding: 15px;
    background-color: theme-color('blue');
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.product-thumb:hover .product-title{
    background-color: theme-color('green'); 
}
.product-title h3 {
    color: #fff;
    text-transform: capitalize;
    margin: 0px;
}
.product-title hr {
    border-top: 1px solid rgba(255, 255, 255, 1);
    margin-top: .5em;
    margin-bottom: 0px;
}

/* Panels (Prduct Detials) */
.panel-group {
    position: relative;
}
.panel-group:before {
    content: "";
    position: absolute;
    top: 20px;
    bottom: 17px;
    left: 18px;
    border-left: dotted 2px theme-color('primary');
    z-index: 0;
}

.panel h3 {
    text-transform: capitalize;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}
.panel h3:hover {
    cursor: pointer;
}
.panel h3:before {
    content: "";
    background-color: #fff;
    border: 1px solid theme-color("primary");
    width: 14px;
    height: 14px;
    margin: 2px 12px 0;
    position: relative;
    z-index: 10;
    border-radius: 50%;
}
.panel > div[aria-expanded="true"] h3:before {
    background-color: theme-color("lt-blue");
}
.panel > div[aria-expanded="true"] h3 {
    color: theme-color("lt-blue");
}
.panel-collapse .panel-body {
    padding-left: 40px;
}

/* Forms */
#formContainer input {
    width: 100%;
    margin: 4px 0px;
    padding: 5px 5px;
}
#formContainer .submit { 
    display: inline-block;
    padding: 7px 40px; 
    margin: 5px 0px;
    color: #fff;
    border-radius: 0px;
    max-width: 168px; 
    font-size: 17px;
    background-color: theme-color('green'); 
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
#formContainer .submit:hover { 
    color: #fff;
    background-color: theme-color('blue'); 
    cursor: pointer;
    
}
#formContainer .state {
    margin: 5px 0px;
    height: 34px;
    width: 100%;
    min-width: 65px
}
#formContainer .message {
    width: 100%;
    margin: 4px 0px;
    height: 150px;
}
.responseMessage {
    background-color: #fff;
    margin: 5px 0px;
    padding: 15px;
    color: green;
    display: none;
    text-align: center;
}

/* New Doctor Page */
#steps-wrapper {
    display: flex;
    margin: auto;
    padding: 35px 0px;
}
.steps-flex {
    display: flex;
}
.step-circle {
    display: flex;
    border-radius: 50%;
    border: solid 2px theme-color('blue');
    color: theme-color('blue');
    background-color: #fff;
    text-align: center;
    height: 32px;
    width: 32px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
}
.step-circle div { 
    align-self: center;
    margin: auto;
    font-size: 15px;
    padding-right: 2px;
}
.step-circle.active-step {
    color: #fff;
    background-color: theme-color('blue');
}
.step-circle.expired-step {
    color: #fff;
    background-color: theme-color('blue');
}
.nav-circle {
    height: 7px;
    width: 7px;
    float: left;
    margin: 0px 3.5px;
    border-radius: 50%;
    border: solid 1.8px theme-color('blue');
    background-color: #fff;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
}
.nav-circle.active-circle {
    background-color: theme-color('blue');
}
.nav-circle.solid-circle {
    background-color: theme-color('blue');
}
.nav-circle-wrapper {
    display: flex;
    align-self: center;
    padding: 0px 0px;
}
#step-conent-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
#step-conent-wrapper div {
    text-align: center;
}
#step-conent-wrapper h2 {
    color: theme-color('blue');
}
.center-divide {
    width: 100%;
    max-width: 300px;
    margin: auto;
    height: 1px;
    background-color: #76777a;
    margin-bottom: 5px;
}
.step-content-block {
    grid-column-start: 1;
    grid-row-start: 1;
}
.step-content-wrap {
    padding: 25px 0px;
    width: 100%;
    margin: auto;
    max-width: 800px;
}
.hide-content {
    display: none;
}
.arrow-animate {
    animation: animate-fa-arrow 2s ease infinite;
}
@keyframes animate-fa-arrow {
    0% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    6% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    12% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    18% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    24% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    30% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    100%, 36% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
}

/* Swiper */
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-pagination-bullet-active {
    background: #fff;
}

/* Zip Code Checker */
#zipcode_Field {
    margin: auto;
    width: 100%;
    max-width: 300px;
}

input[type="radio"], input[type="checkbox"] {
    min-width: 15px;
}

/* sm devices */ 
@media screen and (min-width: 576px) { 
    #home-nav-container{ max-width: 540px; }
    #home-slider { height: 644px; }
    .home-slide h1 { font-size: 3em; }
    .home-slide h2 { font-size: 2.3em; } 
    .step-circle div { font-size: 30px; }
    .step-circle { width: 50px; height: 50px; }
    .nav-circle-wrapper { padding: 0px 5px; }
} 
 
/* md devices */ 
@media screen and (min-width: 768px) { 
    .icon-boxes-grid { grid-template-columns: repeat(2, 1fr); }
    #product-listing { grid-template-columns: repeat(2, 1fr); }
    #home-nav-container{ max-width: 720px; }
    .result-wrapper{ grid-template-columns: repeat(1, 1fr); }
} 
 
/* lg devices */ 
@media screen and (min-width: 992px) { 
    #product-listing { grid-template-columns: repeat(4, 1fr); }
    #home-nav-container{ max-width: 960px; }
} 

/* xl devices */ 
@media screen and (min-width: 1200px) { 
    #home-nav-container{ max-width: 1140px; }
} 